jQuery(document).ready(function($){
    // init slider
    $('.elonisas-slider').slick({
        // autoplay: true,
        // autoplaySpeed: 3000,
        arrows: true,
        dots: true,
        infinite: true,
        nextArrow: '<i class="next fas fa fa-chevron-right"></i>',
        pauseOnDotsHover: false,
        prevArrow: '<i class="prev fas fa fa-chevron-left"></i>',
        slidesToScroll: 1,
        slidesToShow: 1,
        swipeToSlide: true,
        speed: 500
    });
});



